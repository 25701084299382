import React, { useEffect, useRef, useState } from 'react';
import { FilterIcon } from 'components/common/icons/FilterIcon';
import { SearchOverlay } from './searchOverlay/SearchOverlay/SearchOverlay';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { Btn, InputSearch } from '../HeaderSearch/HeaderSearch.styles';
import { Popover } from '@app/components/common/Popover/Popover';
import { SearchResult } from '@app/api/summary.api';
import { SearchCategoryType } from '@app/constants/categoriesList';

interface SearchOverlayProps {
  query: string;
  setQuery: (query: string) => void;
  data: SearchResult[] | null;
  isOverlayOpen: boolean;
  isSearching: boolean;
  setOverlayOpen: (state: boolean) => void;
  onSelectType: (type: SearchCategoryType) => void;
}

export const SearchDropdown: React.FC<SearchOverlayProps> = ({
  query,
  setQuery,
  data,
  isOverlayOpen,
  isSearching,
  setOverlayOpen,
  onSelectType,
}) => {
  const [isFilterOpen, setFilterOpen] = useState(true);

  useEffect(() => {
    setOverlayOpen(!!query || isFilterOpen);
  }, [query, isFilterOpen, setOverlayOpen]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  return (
    <>
      <Popover
        {...((!!data || isFilterOpen) && {
          trigger: 'click',
          onOpenChange: setOverlayOpen,
        })}
        overlayClassName="search-overlay"
        content={
          <SearchOverlay
            data={data}
            isFilterOpen={isFilterOpen}
            onSelectType={onSelectType}
            isSearching={isSearching}
          />
        }
        open={isOverlayOpen}
        getPopupContainer={() => ref.current}
      >
        <HeaderActionWrapper>
          <InputSearch
            width="100%"
            value={query}
            placeholder="Search"
            filter={
              <Btn
                size="small"
                type={isFilterOpen ? 'ghost' : 'text'}
                aria-label="Filter"
                icon={<FilterIcon />}
                onClick={() => setFilterOpen(!isFilterOpen)}
              />
            }
            onChange={(event) => setQuery(event.target.value)}
            enterButton={null}
            addonAfter={null}
          />
          <div ref={ref} />
        </HeaderActionWrapper>
      </Popover>
    </>
  );
};
