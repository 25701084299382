import { httpApi } from '@app/api/http.api';
import { UploadFile } from 'antd';

export interface ServiceEvent {
  date: string;
  doctor: number;
  services: Service[];
}

export interface ServiceItem {
  id: number;
  itemid: string;
  item: string;
  description: string;
  order: number;
  itemrequired: boolean;
  itemactive: boolean;
}

export interface ServiceCheckItem {
  id: number;
  descriptions: string;
  inventorylist: object | undefined;
  checked: boolean | undefined;
}

export interface ServiceFile {
  fileid: string;
  filename: string;
  creadtedon: string;
}

export interface ServiceHistory {
  id: number;
  historyid: string;
  servicestatus: number;
  status: string;
  notes: string;
  createdon: string;
}

export interface Service {
  reference: string;
  serviceid: string;
  servicevehicle: string;
  servicerego: string;
  serviceodo: string;
  servicestatus: number;
  servicestatusname: string;
  servicedate: string;
  customername: string;
  customerid: string;
  creadtedon: string;
  iswarranty: number;
  warrantycompany: string;
  servicenotes: string;
  serviceitems: ServiceCheckItem[];
  completenotes: string;
  emailcustomer: boolean;
  wofexpireon: string;
  regoexpireon: string;
  jobsdone: string;
  promocode: string;
  items: ServiceItem[];
  history: ServiceHistory[];
  files: ServiceFile[];
  photos: ServiceFile[];
}

export interface ServiceSchedule {
  date: string;
  services: Service[];
}

export interface ServiceChecklist {
  id: number;
  value: boolean;
  description: string | undefined;
}

export interface ServicesResults {
  data: Service[];
  total: number;
}

export interface ServicesInvoiceResults {
  success: boolean;
  invoiceid: string;
}

export interface ServiceImagePreviewResults {
  images: ServiceImagePreview[];
}

export interface ServiceImagePreview {
  identifier: string;
  name: string;
  description: string;
  data: string;
}

export const getServices = (page: number, pageSize: number, status: number): Promise<ServicesResults> => {
  const params = { pageSize: pageSize, page: page, status: status };
  return httpApi.get<ServicesResults>('/services', { params }).then(({ data }) => data);
};

export const getRecentServices = (limit: number): Promise<Service[]> => {
  const params = { limit: limit };
  return httpApi.get<Service[]>('/services/recent', { params }).then(({ data }) => data);
};

export const getService = (serviceId: string): Promise<Service> => {
  return httpApi.get<Service>(`/service/${serviceId}`).then(({ data }) => data);
};

export const getServiceSchedules = (year: number, month: number): Promise<ServiceSchedule[]> => {
  return httpApi.get<ServiceSchedule[]>(`/services/schedule/${year}/${month}`).then(({ data }) => data);
};

export const createService = (service: any): Promise<void> => {
  return httpApi.post('/service', service).then(({ data }) => data);
};

export const completeService = (serviceId: string, service: any): Promise<void> => {
  return httpApi.post(`/service/${serviceId}/complete`, service).then(({ data }) => data);
};

export const checkServiceItem = (serviceId: string, serviceItem: string): Promise<void> => {
  const params = { serviceItem: serviceItem };
  return httpApi.put(`/service/${serviceId}/items`, params).then(({ data }) => data);
};

export const uploadFile = (serviceId: string, file: FormData): Promise<any> => {
  return httpApi.post(`/service/${serviceId}/file/upload`, file).then(({ data }) => data);
};

export const generateInvoice = (serviceId: string): Promise<ServicesInvoiceResults> => {
  return httpApi.get<ServicesInvoiceResults>(`/service/${serviceId}/invoice`).then(({ data }) => data);
};

export const deleteFile = (serviceId: string, fileId: string): Promise<any> => {
  return httpApi.get(`/service/${serviceId}/file/${fileId}/delete`).then(({ data }) => data);
};

export const getImagePreviews = (serviceId: string): Promise<ServiceImagePreviewResults> => {
  return httpApi.get<ServiceImagePreviewResults>(`/service/${serviceId}/file/preview`).then(({ data }) => data);
};

export const downloadFile = (serviceId: string, file: UploadFile): Promise<void> => {
  return httpApi
    .get(`/service/${serviceId}/file/${file.uid}/download`, {
      responseType: 'blob',
    })
    .then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => console.log(error));
};
