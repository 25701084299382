export type SearchCategoryType = 'customers' | 'services' | 'inventory' | 'invoice';

interface SearchCategory {
  name: SearchCategoryType;
  title: string;
}

export const categoriesList: SearchCategory[] = [
  {
    name: 'customers',
    title: 'Customers',
  },
  {
    name: 'inventory',
    title: 'Inventory',
  },
  {
    name: 'services',
    title: 'Jobs',
  },
  {
    name: 'invoice',
    title: 'Invoice',
  },
];
